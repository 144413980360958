import * as React from "react";
import {useEffect, useState} from "react";
import styled from "styled-components";
import {Button, Flex, Image} from "antd";
import Prism from 'prismjs';
import "prismjs/components/prism-python";
import "./PrismTheme.css";

const Container = styled.div`
        border-radius: ${props => props.theme.borderRadius.smd};
        box-shadow: ${props => props.theme.boxShadow.default};
    `;

const Pre = styled.pre`
        border-radius: ${props => `0 0 ${props.theme.borderRadius.smd} ${props.theme.borderRadius.smd}`};

        && {
            margin: 0;
        }
    `;

const Code = styled.code`
        && {
            font-size: ${props => props.theme.fontSize.md};
        }
    `;

const TitleContainer = styled(Flex)`
        background-color: #292f43;
        border-radius: ${props => `${props.theme.borderRadius.smd} ${props.theme.borderRadius.smd} 0 0`};
        box-shadow: ${props => props.theme.boxShadow.default};
        padding: 0.75rem 1rem;
        position: relative;
    `;

const Title = styled.h2`
        color: ${props => props.theme.colors.lightestGray};
        font-size: ${props => props.theme.fontSize.md};
        font-weight: ${props => props.theme.fontWeight.regular};
        margin: 0 0 0 .5rem;
        opacity: 0.75;
    `;

const CopyButton = styled(Button)`
    margin-left: auto;
    height: 25px;
`;

const copyImage = <Image src="/images/copy-link-icon.svg" alt="Copy to clipboard" preview={false} width="1rem"/>
const copiedImage = <Image src="/images/check-icon.svg" alt="Copied icon" preview={false} width="1.25rem"/>

const CodeBlock = ({ input = true, children }) => {
    const [copyIcon, setCopyIcon] = useState(copyImage);

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const handleCopy = async () => {
        await navigator.clipboard.writeText(children);
        setCopyIcon(copiedImage);
        setTimeout(() => setCopyIcon(copyImage), 1500);
    };

    return (
        <Container>
            <TitleContainer>
                {input && <Image src="/images/file-icon.svg" alt="Terminal icon" width="0.75rem" preview={false}/>}
                <Title>{input ? "Python" : "Returns"}</Title>
                <CopyButton type="text" icon={copyIcon} onClick={handleCopy}/>
            </TitleContainer>
            <Pre>
                <Code className="language-python">
                    {children}
                </Code>
            </Pre>
        </Container>
    );
};

export default CodeBlock;
