import {Table} from "antd";
import styled from "styled-components";

const StyledTable = styled(Table)`
    width: 29rem;

    .ant-table-container {
        border-bottom-left-radius: ${props => props.theme.borderRadius.md};
    }

    thead {
        font-size: ${props => props.theme.fontSize.md};
        text-transform: uppercase;
    }

    tbody {
        background-color: ${props => props.theme.colors.lightestGray};
    }

    thead tr:not(:first-of-type) {
        display: none;
    }

    tr:last-child td:first-of-type {
        border-bottom-left-radius: ${props => props.theme.borderRadius.md};
    }

    tr:last-child td:last-child {
        border-bottom-right-radius: ${props => props.theme.borderRadius.md};
    }

`;

export default StyledTable;
