import * as React from "react";
import Layout from "../components/Layout";
import ExpectationPage from "../components/galleryV2/ExpectationPage";
import SEO from "../components/seo";

function Expectation({ pageContext, location }) {
    const expectation = pageContext.expectation;

    return (
        <Layout>
            <SEO
                title={pageContext?.expectation?.title}
                description={pageContext?.expectation?.description?.short_description}
            />
            <ExpectationPage expectation={expectation} location={location}/>
        </Layout>
    );
}
export default Expectation;