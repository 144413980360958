import * as React from "react";
import styled from "styled-components";
import ProvidersWrapper from "./ProvidersWrapper";
import ExpectationDetails from "./ExpectationDetails";
import {Breadcrumb, Typography} from "antd";
import { RightOutlined } from '@ant-design/icons';
import isLoading from "./shared/IsLoading";
import CustomSkeleton from "./shared/Skeleton";
const { Link } = Typography;

const Wrapper = styled.section`
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 2rem 2rem;
    background-color: ${props => props.theme.colors.white};

    li {
        font-size: inherit;
        line-height: inherit;
    }

    h2 {
        font-family: Source Sans Pro, sans-serif;
    }
`;

const BreadcrumbWrapper = styled.div`
    background-color: ${props => props.theme.colors.lightGray};

    ol {
        max-width: 100%;
    }

    li {
        font-size: inherit;
        line-height: inherit;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`

// TODO: check in gatsby page if this styling can be moved to the antd's global theme
const StyledBreadcrumb = styled(Breadcrumb)`
    display: flex;
    justify-content: flex-start;
    padding: 1rem 2rem;
    font-size: 1.125rem;
    margin: 0 auto;
    max-width: 1200px;
`;

const StyledLink = styled(Link)`
    font-size: 1.125rem;
    &&{
        &:hover {
            background: none;
        }
    }
`;

const ExpectationPage = ({ expectation, location }) => {
    const loading = isLoading();

    if (loading) {
        return <CustomSkeleton/>
    }

    return (
        <ProvidersWrapper>
            <BreadcrumbWrapper>
                <StyledBreadcrumb separator={<RightOutlined />} items={[{ title: <StyledLink href="/expectations">Expectation Gallery</StyledLink>}, {title: expectation.title}]}/>
            </BreadcrumbWrapper>
            <Wrapper>
                <ExpectationDetails expectation={expectation} location={location}/>
            </Wrapper>
        </ProvidersWrapper>
    );
};

export default ExpectationPage;
