import * as React from "react";
import {Table} from "antd";
import StyledTable from "./shared/StyledTable";
import styled from "styled-components";

const { Column, ColumnGroup } = Table;

const DataExampleTable = styled(StyledTable)`
    width: 20rem;
    margin-bottom: 1rem;
`

const Examples = ({ exampleData }) => {

    const parseData = (exampleData) => {
        return exampleData.map( table => {
            const { header, data } = table;
            const dataRows = data.map((dataLine, index) => {
                const dataLinesContent = {};
                dataLine.forEach((dataLineContent, i) => {
                    dataLinesContent[`content${i + 1}`] = dataLineContent;
                });

                return {
                    key: index + 1,
                    content0: index,
                    ...dataLinesContent
                };
            });

            const headerLinesContent = {};
            header.forEach((headerLineContent, i) => {
                headerLinesContent[`content${i + 1}`] = headerLineContent;
            });
            return [
                {
                    key: "0",
                    content0: "",
                    ...headerLinesContent
                },
                ...dataRows
            ];
        })
    };

    return <>
        {
            parseData(exampleData).map((table, index) => {
                return <DataExampleTable dataSource={table} pagination={false}
                                 bordered={true} size="small">
                        <ColumnGroup title={"Sample data" + ( exampleData.length == 1 ? '' : ` #${index + 1}`)} align="left">
                            <Column title="Number" dataIndex="content0" key="content0"/>
                            {exampleData[index].header.map((headerField, idx) =>
                                <Column title={headerField} dataIndex={`content${idx + 1}`} key={`content${idx + 1}`}/>
                            )}
                        </ColumnGroup>
                    </DataExampleTable>
            })
        }
    </>
};

export default Examples;
